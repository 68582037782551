<template>
  <article class="container-base">
    <section class="breadcrumb">
      <router-link :to="{ name: 'Home' }" class="breadcrumbT">
        <img src="@/assets/BreadcrumbHome.png" alt="Home" class="breadcrumbImg">
      </router-link>
      <img src="@/assets/IconeSeta.png" alt="Home" class="iconImg">
      <h1>ONDE ESTAMOS</h1>
    </section>
    <p class="subh">
      A AHBB | Rede Santa Casa acredita acolhimento aliado a profissionalismo e transparência constroem uma gestão eficaz. Conheça abaixo nossos projetos:
    </p>
    <div class="containerFAlign">
      <section class="filters">
        <div class="btnFilter">
          <p @click="showformChange()" class="filter">
            <img src="@/assets/filterIcon.png" alt="Filtrar" class="filterIcon">
            Filtrar
          </p>
        </div>
        <div v-if="showform">
          <div class="filterUnits">
            <span class="filterSpan"><b>Estado</b></span>
            <select class="selectClass" v-model="estado" @change="filtrarEstado()">
              <option
                class="optionClass"
                v-for="(optionE, o) in optionsE"
                :value="optionE.value"
                :key="o"
              >
                {{ optionE.text }}
              </option>
            </select>

            <span class="filterSpan"><b>Cidade</b></span>
            <select class="selectClass" v-model="cidade" @change="filtrarCidade()">
              <option
                class="optionClass"
                v-for="(optionC, op) in optionsC"
                :value="optionC.value"
                :key="op"
              >
                {{ optionC.text }}
              </option>
            </select>
          </div>

          <div class="filterStats">
            <span class="filterSpan"><b>Status do Projeto</b></span>
            <div class="alignBtnStats">
              <a class="btnClose" name="opened" v-if="btnCloseO" @click="filtersSC('opened')">
                X
              </a>
              <button @click="filtersSC('opened')" class="btn">
                Em Aberto
                <div class="btnUnderline" v-if="btnCloseO" />
              </button>

              <div class="btnClose" v-if="btnCloseE" @click="filtersSC('closed')">
                X
              </div>
              <button @click="filtersSC('closed')" class="btn">
                Encerrado
                <div class="btnUnderline" v-if="btnCloseE" />
              </button>
            </div>

            <span class="filterSpan"><b>Categorias</b></span>
            <div class="alignBtnCategory">
              <div class="btnClose" v-if="btnCloseG" @click="filtersSC('geral')">
                X
              </div>
              <button @click="filtersSC('geral')" class="btn">
                Geral
                <div class="btnUnderline" v-if="btnCloseG" />
              </button>
              
              <div class="btnClose" v-if="btnCloseCovid" @click="filtersSC('covid19')">
                X
              </div>
              <button @click="filtersSC('covid19')" class="btn">
                Covid-19
                <div class="btnUnderline" v-if="btnCloseCovid" />
              </button>
            </div>
          </div>
        </div>
      </section>
      <div class="zeroResultsClass" v-if="zeroResults">
        <p class="zeroResultsP">
          Não há resultados para os filtros selecionados<br>
          Tente <a href="#" class="linkClass" @click="cleanFilters">limpar os filtros</a> e começar novamente.
        </p>
      </div>
      <div class="lista-locais" v-if="!zeroResults">
        <router-link
          v-for="(loc, i) of locais"
          :key="i"
          :to="loc.id ? `/onde-estamos/${loc.id}` : '#'"
          class="card-local"
        >
          <cartao :loading="loadingLocais" :item-index="i" :animate="true" :key="rerender">
            <template v-slot:imagem>
              <img
                v-if="loc.foto"
                class="foto-local"
                v-lazy="cms.getAssetUrl(loc.foto.private_hash, {w: 600, h: 720, f: 'contain', q: 90})"
                :data-srcset="`
                  ${cms.getAssetUrl(loc.foto.private_hash, {w: 320, h: 720, f: 'contain', q: 90})} 320w,
                  ${cms.getAssetUrl(loc.foto.private_hash, {w: 450, h: 720, f: 'contain', q: 90})} 450w,
                  ${cms.getAssetUrl(loc.foto.private_hash, {w: 600, h: 720, f: 'contain', q: 90})} 600w,
                  ${cms.getAssetUrl(loc.foto.private_hash, {w: 1200, h: 720, f: 'contain', q: 90})} 1200w,
                `"
                sizes="
                  (max-width: 740px) 600px,
                  450px,
                  (min-width: 1496px) 320px,
                "
              >
            </template>
            <template v-slot:toast v-if="loc.encerrado">
              Projeto Encerrado
            </template>
            <template v-slot:legenda>
              <span v-if="!loadingLocais">Cidade: </span><span>{{ loc.cidade }}</span><br>
              <div class="nomeLocal">
                <h3>
                  {{ loc.nome }}
                </h3>
              </div>
            </template>
          </cartao>
        </router-link>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>

  .btnFilter{
    display: flex;
    margin: 0 0 16px;
    width: 80px;
  }

  p{
    display: flex;
    margin: 0;;
  }

  .filter{
    font-size: 16px;
    font-weight: 700;
  }

  .filterIcon{
    width: 18px;
    height: 17px;
    margin: 2px 8px 0 0;
  }

  .selectClass{
    display: flex;
    position: relative;
    width: 90%;
    height: 32px;
    margin: 3px 0 16px;
    border-color: rgba($color: #000000, $alpha: 0.1);
    border-width: 1px;;
    padding: 8px 10px;
   }

   .selectClass:focus{
     outline: 0;
   }

   .optionClass{
    font-size: 12px;
    border: rgba($color: #000000, $alpha: 0.1);
   }

   .zeroResultsClass{
     display: flex;
     justify-content: center;
     align-items: center;
     min-width: 70%;
   }

   .zeroResultsP{
     display: block;
     font-size: 24px;
   }

   a{
     color: #245070;

   }

  .filters{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 32px 10px 0 0;
    padding: 2px;
  }

  .filterUnits, .filterStats{
    padding: 2px;
    margin: 6px 0 3px;
  }

  .alignBtnStats{
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .alignBtnCategory{
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .btnClose{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #F4F5F6;
    font-size: 8px;
    font-weight: 700;
    color: rgba($color: #83B76A, $alpha: 1);
    width: 12px;
    height: 12px;
    margin: 5px 4px 16px 0;
    cursor: pointer;
  }

  .btn{
    text-align: left;
    color: rgba($color: #83B76A, $alpha: 1);
    font-size: 10px;
    font-family: montserrat, bold;
    margin: 3px 32px 13px 0;
    padding: 5px 0 5px 0;
    border-radius: 0;
  }

  .btnUnderline{
    position: inherit;
    height: 2px;
    background-image: linear-gradient(to right, #83B76A 100%, transparent 100%);
    background-position: 0 1px;
    background-repeat: repeat-x;
    background-size: 1px 1px;
  }

  span{
    font-size: 16px;
  }

  .filterSpan{
    font-size: 12px;
  }

  .containerFAlign{
    display: flex;
  }

  h1{
    display: block;
    margin: 0 0 0 9px;
  }

  @media (max-width: 768px) {
    .filters{
      width: 600px;
    }
  }

  @media (max-width: 505px) {
    .containerFAlign{
      display: block;
    }

    .filters{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 32px 10px 0 0;
      padding: 2px;
    }

    .selectClass{
      background-color: rgba($color: #fff, $alpha: 0.1)
   }

  }

  .breadcrumb{
    display: flex;
    margin: 0 0 32px;
  }

  .breadcrumbImg{
    margin: 6px 9px 0 9px;
    width: 25px;
    height: 25px;
  }

  .iconImg{
    margin: 13px 9px 0 9px;
    width: 6px;
    height: 10px;
  }

 @media (max-width: 430px) {
    .iconImg{
    margin: 13px 9px 0 9px;
    width: 4px;
    height: 8px;
    }

    .breadcrumbImg{
    margin: 6px 9px 0 9px;
    width: 20px;
    height: 20px;
    }

    h1{
    display: block;
    margin: 5px 0 0 9px;
    font-size: 18px;
    }
  }

  .lista-locais {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 22px;
    margin-top: 32px;
    margin-bottom: 80px;
    width: 100%;

    .card-local {
      text-decoration: none;

      figure {
        display: flex;
        height: 320px;
        margin: 0;

        flex-direction: column;

        background-color: #293B599b;

        overflow: hidden;

        animation: fade-down .3s ease-out both;

        .foto-local {
          flex: 1 0 0;
          min-height: 0;
          width: 100%;

          mix-blend-mode: multiply;

          object-fit: cover;
          object-position: center;

          transition: transform .2s ease-in-out, opacity .2s ease-in-out;
        }

        &:hover {
          cursor: pointer;

          .foto-local {
            transform: scale(1.1);
          }
        }

        figcaption {
          width: 100px;
          
          padding: 20px;
          z-index: 1;

          flex: 0 0 auto;

          background: var(--ahbb-blue-gradient);
          color: white;

          font-size: 20px;
            .nomeLocal{
              flex: 1;
              min-width: 100px;
                h3{
                  font-size: 17px;
                  margin: 0;
                  padding: 8px 0;
                  color: white;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
            }
        }

        &.loading {
          animation: fade-down .3s ease-out both, pulse 3s infinite alternate linear both;

          figcaption * {
            background-color: #fff2;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  @keyframes fade-down {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
  }
</style>

<script>
import cms from '@/lib/cms.js'
import Cartao from '@/components/Cartao.vue'

export default ({
  name: 'OndeEstamos',
  data: () => ({
    locais: Array(3).fill([
      {
        nome: '                 ',
        cidade: '                                       ',
      },
      {
        nome: '                       ',
        cidade: '                                                   ',
      },
      {
        nome: '                                       ',
        cidade: '                                         ',
      },
      {
        nome: '                       ',
        cidade: '                                                   ',
      },
    ]).flat(),
    cms,
    locaisOriginal: [],
    locaisName: '',
    loadingLocais: false,
    showform: false,
    widthPage: '',
    zeroResults: false,
    btnCloseO: false,
    btnCloseE: false,
    btnCloseG: false,
    btnCloseCovid: false,
    rerender: 0,
    estado: 'Sel',
    optionsE: [],
    cidade: 'Sel',
    optionsCOriginal: [], // A mesma ideia da variável "locaisOriginal".
    optionsC: [],

    statusProjeto: null,
    categoriaCovid: null,
  }),

  async created() {
    this.locais = (await cms.getItems(
      'onde_estamos',
      { fields: 'id,nome,cidade,covid19,encerrado,foto.private_hash' },
    )).data

    const getCidades = (await cms.getItems(
      'onde_estamos',
      { fields: 'cidade' },
    )).data

    const citiesObj = getCidades.map(cities => {
      const city = cities.cidade.trim().split(' - ')[0]
      const value = city.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      const state = cities.cidade.trim().split(' - ')[1]

      return { text: city, value: value, state: state }
    })

    const stateObj = getCidades.map(states => {
      const state = states.cidade.trim().split(' - ')[1]
      const value = state.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      return { text: state, value: value }
    })
    
    const citiesArray = new Map()
    const setState = new Map()

    citiesObj.forEach((city) => {
      if (!citiesArray.has(city.text))
        citiesArray.set(city.text, city)
    })

    stateObj.forEach((state) => {
      if (!setState.has(state.state))
        setState.set(state.state, state)
    })

    this.optionsC = [...citiesArray.values()]
    this.optionsE = [...setState.values()]
    this.optionsC.push({ text: 'Selecione a cidade', value: 'Sel', state: 'Sel' })
    this.optionsE.push({ text: 'Selecione o estado', value: 'Sel' })

    this.locaisOriginal = this.locais
    this.optionsCOriginal = this.optionsC // A mesma ideia da variável "locaisOriginal".
    this.widthPage = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (this.widthPage <= '505')
      this.showform = false

    else
      this.showform = true
  },
  methods: {
    rerenderFunction() {
      this.rerender += 1
    },

    filtrarEstado() {
      if (this.estado === 'Sel' && this.cidade === 'Sel') {
        this.optionsC = this.optionsCOriginal
        this.closeBtns()
        this.rerenderFunction()
        return this.locais = this.locaisOriginal
      }

      else if (this.estado === 'Sel' && this.cidade !== 'Sel') {
        this.closeBtns()
        this.optionsC = this.optionsCOriginal
        return this.filtrarCidade()
      }

      else {
        this.cidade = 'Sel'
        this.closeBtns()
        this.rerenderFunction()
        const estadoFiltro = this.estado?.toUpperCase()
        this.locais = this.locaisOriginal.filter(cidade => {
          const estado = cidade.cidade.toUpperCase().trim().split(' - ')[1]
          return estado === estadoFiltro
        })
        this.locais.length === 0 ? this.zeroResults = true : this.zeroResults = false
        this.optionsC = this.optionsCOriginal.filter(cidade => {
          return cidade.state === estadoFiltro || cidade.state === 'Sel'
        })
      }
    },

    filtrarCidade() {
      if (this.cidade === 'Sel' && this.estado !== 'Sel') {
        this.rerenderFunction()
        return this.filtrarEstado()
      }
      else if (this.estado === 'Sel' && this.cidade === 'Sel') {
        this.rerenderFunction()
        return this.locais = this.locaisOriginal
      }

      this.rerenderFunction()
      this.closeBtns()
      let cidadeFiltro = this.cidade.toUpperCase()
      cidadeFiltro = cidadeFiltro.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      
      this.locais = this.locaisOriginal.filter(cidade => {
        const _cidade = cidade.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')

        return _cidade === cidadeFiltro
      })
      
      this.locais.length === 0 ? this.zeroResults = true : this.zeroResults = false
    },

    openedFunction() {
      const cidade_ = this.cidade.toUpperCase()
      const estado_ = this.estado?.toUpperCase()
      let result = false
      if (this.btnCloseO && this.btnCloseCovid === false) {
        this.locais = this.locaisOriginal.filter(projetos => {
          const projEncerrado = projetos.encerrado
          const _estado = projetos.cidade.toUpperCase().trim().split(' - ')[1]
          const _cidade = projetos.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')

          if (projEncerrado === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = projEncerrado === false
              
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && _estado === estado_

          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado === false && _cidade === cidade_

          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && _estado === estado_ && _cidade === cidade_

          this.rerenderFunction()
          return result
        })
            
        if (this.locais.length === 0)
          this.zeroResults = true
      }

      else if (this.btnCloseO && this.btnCloseCovid) {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const projEncerrado = categoryOC.encerrado
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]
          const _cidade = categoryOC.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')

          if (projEncerrado === undefined || categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected && projEncerrado === false
              
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && categorySelected && _estado === estado_

          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado === false && categorySelected && _cidade === cidade_

          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && _estado === estado_ && _cidade === cidade_ && categorySelected

          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseO === false && this.btnCloseCovid && this.cidade === 'Sel') {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]

          if (categorySelected === undefined)
            this.zeroResults = true

          if (this.estado !== 'Sel')
            result = _estado === estado_ && categorySelected
            
          else
            result = categorySelected

          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
    },

    closedFunction() {
      const cidade_ = this.cidade.toUpperCase()
      const estado_ = this.estado?.toUpperCase()
      let result = false

      if (this.btnCloseE && this.btnCloseCovid === false) {
        this.locais = this.locaisOriginal.filter(projetos => {
          const projEncerrado = projetos.encerrado
          const _cidade = projetos.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = projetos.cidade.toUpperCase().trim().split(' - ')[1]

          if (projEncerrado === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = projEncerrado
              
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && _estado === estado_ && _cidade === cidade_

          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseE && this.btnCloseCovid) {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const projEncerrado = categoryOC.encerrado
          const _cidade = categoryOC.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]

          if (projEncerrado === undefined || categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected && projEncerrado
              
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && categorySelected && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado && categorySelected && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && _estado === estado_ && _cidade === cidade_ && categorySelected

          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseE === false && this.btnCloseCovid && this.cidade === 'Sel') {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]

          if (categorySelected === undefined)
            this.zeroResults = true

          if (this.estado !== 'Sel')

            result = _estado === estado_ && categorySelected
            
          else
            result = categorySelected

          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
    },

    covid19Function() {
      const cidade_ = this.cidade.toUpperCase()
      const estado_ = this.estado?.toUpperCase()
      let result = false

      if (this.btnCloseCovid && this.btnCloseO === false && this.btnCloseE === false) {
        this.locais = this.locaisOriginal.filter(category => {
          const categorySelected = category.covid19
          const _cidade = category.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = category.cidade.toUpperCase().trim().split(' - ')[1]
          
          if (categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected
          
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = categorySelected && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = categorySelected && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = categorySelected && _estado === estado_ && _cidade === cidade_
          
          this.rerenderFunction()
          return result
        })
        
        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseO && this.btnCloseCovid) {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const projEncerrado = categoryOC.encerrado
          const _cidade = categoryOC.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]

          if (projEncerrado === undefined || categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected && projEncerrado === false
          
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && categorySelected && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado === false && categorySelected && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && _estado === estado_ && _cidade === cidade_ && categorySelected
          
          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseE && this.btnCloseCovid) {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const projEncerrado = categoryOC.encerrado
          const _cidade = categoryOC.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]

          if (projEncerrado === undefined || categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected && projEncerrado
          
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && categorySelected && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado && categorySelected && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && _estado === estado_ && _cidade === cidade_ && categorySelected

          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseE && this.btnCloseCovid === false && cidade_ === 'SEL')
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const projEncerrado = categoryOC.encerrado
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]
          if (projEncerrado === undefined)
            this.zeroResults = true

          if (this.estado !== 'Sel')
            result = projEncerrado && _estado === estado_
            
          else
            result = projEncerrado

          this.rerenderFunction()
          return result
        })
      
      else if (this.btnCloseO && this.btnCloseCovid === false && cidade_ === 'SEL')
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const projEncerrado = categoryOC.encerrado
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]
          if (projEncerrado === undefined)
            this.zeroResults = true

          if (this.estado !== 'Sel')
            result = projEncerrado === false && _estado === estado_
            
          else
            result = projEncerrado === false

          this.rerenderFunction()
          return result
        })
    },

    geralFunction() {
      const cidade_ = this.cidade.toUpperCase()
      const estado_ = this.estado?.toUpperCase()
      let result = false

      if (this.btnCloseG && this.btnCloseO === false && this.btnCloseE === false) {
        this.locais = this.locaisOriginal.filter(category => {
          const categorySelected = category.covid19
          const _cidade = category.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = category.cidade.toUpperCase().trim().split(' - ')[1]
          
          if (categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected === false
          
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = categorySelected === false && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = categorySelected === false && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = categorySelected === false && _estado === estado_ && _cidade === cidade_
          
          this.rerenderFunction()
          return result
        })
        
        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseO && this.btnCloseG) {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const projEncerrado = categoryOC.encerrado
          const _cidade = categoryOC.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]

          if (projEncerrado === undefined || categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected === false && projEncerrado === false
          
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && categorySelected === false && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado === false && categorySelected === false && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado === false && _estado === estado_ && _cidade === cidade_ && categorySelected === false
          
          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseE && this.btnCloseG) {
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const categorySelected = categoryOC.covid19
          const projEncerrado = categoryOC.encerrado
          const _cidade = categoryOC.cidade.toUpperCase().trim().split(' - ')[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]

          if (projEncerrado === undefined || categorySelected === undefined)
            this.zeroResults = true

          if (cidade_ === 'SEL' && estado_ === 'SEL')
            result = categorySelected === false && projEncerrado
          
          else if (cidade_ === 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && categorySelected === false && _estado === estado_
          
          else if (estado_ === 'SEL' && cidade_ !== 'SEL')
            result = projEncerrado && categorySelected === false && _cidade === cidade_
          
          else if (cidade_ !== 'SEL' && estado_ !== 'SEL')
            result = projEncerrado && _estado === estado_ && _cidade === cidade_ && categorySelected === false

          this.rerenderFunction()
          return result
        })

        if (this.locais.length === 0)
          this.zeroResults = true
      }
          
      else if (this.btnCloseE && this.btnCloseG === false && cidade_ === 'SEL')
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const projEncerrado = categoryOC.encerrado
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]
          if (projEncerrado === undefined)
            this.zeroResults = true

          if (this.estado !== 'Sel')
            result = projEncerrado && _estado === estado_
            
          else
            result = projEncerrado

          this.rerenderFunction()
          return result
        })
      
      else if (this.btnCloseO && this.btnCloseG === false && cidade_ === 'SEL')
        this.locais = this.locaisOriginal.filter(categoryOC => {
          const projEncerrado = categoryOC.encerrado
          const _estado = categoryOC.cidade.toUpperCase().trim().split(' - ')[1]
          if (projEncerrado === undefined)
            this.zeroResults = true

          if (this.estado !== 'Sel')
            result = projEncerrado === false && _estado === estado_
            
          else
            result = projEncerrado === false

          this.rerenderFunction()
          return result
        })
    },

    filtersSC(event) {
      this.ChangeBtn(event)
      
      switch (event) {
        case 'opened':
          this.openedFunction()
          
          break

        case 'closed':
          this.closedFunction()
         
          break

        case 'covid19':
          this.covid19Function()
          
          break
        
        case 'geral':
          this.geralFunction()
          
          break

        default:
          return this.locais = this.locaisOriginal
      }
    },

    ChangeBtn(e) {
      if (e === 'opened') {
        this.btnCloseOpened()
        this.btnCloseE = false
      }
      else if (e === 'closed') {
        this.btnCloseEncerrado()
        this.btnCloseO = false
      }
      else if (e === 'geral') {
        this.btnCloseGeral()
        this.btnCloseCovid = false
      }
      else {
        this.btnCloseCovid19()
        this.btnCloseG = false
      }
    },

    renderAjusts() {
      this.zeroResults = false
      this.rerenderFunction()
    },

    btnCloseOpened() {
      if (this.btnCloseO === false)
        this.btnCloseO = true
      
      else {
        this.btnCloseO = false

        if (this.cidade !== 'Sel' && this.btnCloseCovid === false) {
          this.renderAjusts()
          return this.filtrarCidade()
        }

        else if (this.cidade !== 'Sel' && this.btnCloseCovid) {
          this.renderAjusts()
          return this.covid19Function()
        }

        if (this.estado !== 'Sel' && this.btnCloseCovid === false) {
          this.renderAjusts()
          return this.filtrarEstado()
        }
        else if (this.estado !== 'Sel' && this.btnCloseCovid) {
          this.renderAjusts()
          return this.covid19Function()
        }
      }

      this.rerenderFunction()
      return this.locais = this.locaisOriginal
    },

    btnCloseEncerrado() {
      if (this.btnCloseE === false)
        this.btnCloseE = true
      
      else {
        this.btnCloseE = false
        if (this.cidade !== 'Sel' && this.btnCloseCovid === false) {
          this.renderAjusts()
          return this.filtrarCidade()
        }
        else if (this.cidade !== 'Sel' && this.btnCloseCovid) {
          this.renderAjusts()
          return this.covid19Function()
        }

        if (this.estado !== 'Sel' && this.btnCloseCovid === false) {
          this.renderAjusts()
          return this.filtrarEstado()
        }
        else if (this.estado !== 'Sel' && this.btnCloseCovid) {
          this.renderAjusts()
          return this.covid19Function()
        }

        this.rerenderFunction()
        return this.locais = this.locaisOriginal
      }
    },

    btnCloseGeral() {
      if (this.btnCloseG === false)
        this.btnCloseG = true
      
      else {
        this.btnCloseG = false
        if (this.cidade !== 'Sel' && this.btnCloseO === false && this.btnCloseE === false) {
          this.renderAjusts()
          return this.filtrarCidade()
        }
        else if (this.cidade !== 'Sel' && this.btnCloseO) {
          this.renderAjusts()
          return this.openedFunction()
        }
        else if (this.cidade !== 'Sel' && this.btnCloseE) {
          this.renderAjusts()
          return this.closedFunction()
        }

        if (this.estado !== 'Sel' && this.btnCloseO === false && this.btnCloseE === false) {
          this.renderAjusts()
          return this.filtrarEstado()
        }
        else if (this.estado !== 'Sel' && this.btnCloseO) {
          this.renderAjusts()
          return this.openedFunction()
        }
        else if (this.estado !== 'Sel' && this.btnCloseE) {
          this.renderAjusts()
          return this.closedFunction()
        }

        this.rerenderFunction()
        return this.locais = this.locaisOriginal
      }
    },

    btnCloseCovid19() {
      if (this.btnCloseCovid === false)
        this.btnCloseCovid = true
      
      else {
        this.btnCloseCovid = false
        if (this.cidade !== 'Sel' && this.btnCloseO === false && this.btnCloseE === false) {
          this.renderAjusts()
          return this.filtrarCidade()
        }
        else if (this.cidade !== 'Sel' && this.btnCloseO) {
          this.renderAjusts()
          return this.openedFunction()
        }
        else if (this.cidade !== 'Sel' && this.btnCloseE) {
          this.renderAjusts()
          return this.closedFunction()
        }

        if (this.estado !== 'Sel' && this.btnCloseO === false && this.btnCloseE === false) {
          this.renderAjusts()
          return this.filtrarEstado()
        }
        else if (this.estado !== 'Sel' && this.btnCloseO) {
          this.renderAjusts()
          return this.openedFunction()
        }
        else if (this.estado !== 'Sel' && this.btnCloseE) {
          this.renderAjusts()
          return this.closedFunction()
        }

        this.rerenderFunction()
        return this.locais = this.locaisOriginal
      }
    },

    showformChange() {
      if (this.widthPage <= '505')
        if (this.showform)
          this.showform = false

        else
          this.showform = true
    },

    closeBtns() {
      this.btnCloseO = false
      this.btnCloseE = false
      this.btnCloseG = false
      this.btnCloseCovid = false
    },

    cleanFilters() {
      this.estado = 'Sel'
      this.cidade = 'Sel'
      this.closeBtns()
      this.optionsC = this.optionsCOriginal

      this.zeroResults = false
      return this.locais = this.locaisOriginal
    },
  },
  metaInfo: {
    title: 'Onde Estamos',
  },
  components: {
    Cartao,
  },
})
</script>
